import React, { useState, useEffect } from 'react';
import './Features.css';

const Features = () => {
    const [selectedTab, setSelectedTab] = useState('tab1');
    const [isUserInteracted, setIsUserInteracted] = useState(false);

    useEffect(() => {
        if (isUserInteracted) return;

        const tabs = ['tab1', 'tab2', 'tab3'];
        let currentTabIndex = tabs.indexOf(selectedTab);

        const interval = setInterval(() => {
            currentTabIndex = (currentTabIndex + 1) % tabs.length;
            setSelectedTab(tabs[currentTabIndex]);
        }, 5000);

        return () => clearInterval(interval);
    }, [selectedTab, isUserInteracted]);

    const handleTabClick = (tabId) => {
        setSelectedTab(tabId);
        setIsUserInteracted(true);
    };

    return (
        <div id="features-SZ2qsg" className="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto font-light bg-gray-100">
            <div className="relative p-6 md:p-16 bg-white max-w-screen-2xl m-auto shadow-lg rounded-lg">
                <div className="relative z-10 flex flex-col lg:flex-row-reverse items-start gap-12">
                    <div className="lg:w-1/2">
                        <h2 className="text-2xl text-black font-bold sm:text-3xl mb-6">Features</h2>
                        <nav className="grid gap-6" aria-label="Tabs" role="tablist">
                            <button
                                type="button"
                                className={`hover:bg-gray-200 text-start p-4 md:p-5 rounded-xl focus:outline-none focus:ring-1 ${selectedTab === 'tab1' ? 'bg-gray-300' : ''}`}
                                onClick={() => handleTabClick('tab1')}
                                role="tab"
                            >
                                <span className="flex items-center">
                                    <svg className="flex-shrink-0 w-6 h-6 md:w-7 md:h-7 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z"></path>
                                        <path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z"></path>
                                        <path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z"></path>
                                        <path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z"></path>
                                        <path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z"></path>
                                    </svg>
                                    <span className="grow ml-6">
                                        <span className="block text-lg font-semibold text-black">AI Chat Bot</span>
                                        <span className="block mt-1 text-gray-500">Easily communicate with your AI assistant for quick reports.</span>
                                    </span>
                                </span>
                            </button>
                            <button
                                type="button"
                                className={`hover:bg-gray-200 text-start p-4 md:p-5 rounded-xl focus:outline-none focus:ring-1 ${selectedTab === 'tab2' ? 'bg-gray-300' : ''}`}
                                onClick={() => handleTabClick('tab2')}
                                role="tab"
                            >
                                <span className="flex items-center">
                                    <svg className="flex-shrink-0 w-6 h-6 md:w-7 md:h-7 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z"></path>
                                        <path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z"></path>
                                        <path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z"></path>
                                        <path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z"></path>
                                        <path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z"></path>
                                    </svg>
                                    <span className="grow ml-6">
                                        <span className="block text-lg font-semibold text-black">Real-Time Collaboration</span>
                                        <span className="block mt-1 text-gray-500">Facilitate seamless communication and collaboration across teams.</span>
                                    </span>
                                </span>
                            </button>
                            <button
                                type="button"
                                className={`hover:bg-gray-200 text-start p-4 md:p-5 rounded-xl focus:outline-none focus:ring-1 ${selectedTab === 'tab3' ? 'bg-gray-300' : ''}`}
                                onClick={() => handleTabClick('tab3')}
                                role="tab"
                            >
                                <span className="flex items-center">
                                    <svg className="flex-shrink-0 w-6 h-6 md:w-7 md:h-7 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z"></path>
                                        <path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z"></path>
                                        <path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z"></path>
                                        <path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z"></path>
                                        <path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z"></path>
                                    </svg>
                                    <span className="grow ml-6">
                                        <span className="block text-lg font-semibold text-black">Tailored Workflow</span>
                                        <span className="block mt-1 text-gray-500">Flexible workflows that fit the exact needs of your mining projects.</span>
                                    </span>
                                </span>
                            </button>
                        </nav>
                    </div>
                    <div className="lg:w-1/2 lg:order-2">
                        <div className="relative transition-opacity duration-500 ease-in-out">
                            <div className={`tabpanel ${selectedTab === 'tab1' ? 'opacity-100 visible' : 'opacity-0 invisible'} lg:absolute lg:inset-0`} aria-labelledby="tabs-with-card-item-1">
                                <img className="shadow-xl w-full rounded-xl" src="/chatbot.webp" alt="AI Chat Bot" />
                            </div>
                            <div className={`tabpanel ${selectedTab === 'tab2' ? 'opacity-100 visible' : 'opacity-0 invisible'} lg:absolute lg:inset-0`} aria-labelledby="tabs-with-card-item-2">
                                <img className="shadow-xl w-full rounded-xl" src="/collaboration.webp" alt="Real-Time Collaboration" />
                            </div>
                            <div className={`tabpanel ${selectedTab === 'tab3' ? 'opacity-100 visible' : 'opacity-0 invisible'} lg:absolute lg:inset-0`} aria-labelledby="tabs-with-card-item-3">
                                <img className="shadow-xl w-full rounded-xl" src="/workflow.webp" alt="Tailored Workflow" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 lg:hidden">
                    {selectedTab === 'tab1' && <img className="shadow-xl w-full rounded-xl" src="/chatbot.webp" alt="AI Chat Bot" />}
                    {selectedTab === 'tab2' && <img className="shadow-xl w-full rounded-xl" src="/collaboration.webp" alt="Real-Time Collaboration" />}
                    {selectedTab === 'tab3' && <img className="shadow-xl w-full rounded-xl" src="/workflow.webp" alt="Tailored Workflow" />}
                </div>
            </div>
        </div>
    );
};

export default Features;
