import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const PainPointsSection = ({ openModal }) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (observer && observer.current) {
                observer.unobserve(observer.current);
            }
        };
    }, []);

    const renderTable = (header, rows) => (
        <div className={`overflow-x-auto shadow-lg rounded-lg transition duration-500 ease-in-out ${isVisible ? 'animate-slideIn' : 'opacity-0'} mb-8 md:mb-0`}>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-gray-100 text-center">
                        <th className="py-3 px-4 text-gray-800 border-b border-gray-300">{header}</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={index} className="border-b border-gray-300 hover:bg-gray-50 transition duration-300 ease-in-out">
                            <td className="p-4 md:p-8">
                                <div className="flex items-start space-x-4">
                                    <FontAwesomeIcon icon={row.icon} className={`w-6 h-6 ${row.iconColor}`} />
                                    <div>
                                        <h4 className="text-lg font-semibold text-gray-700">{row.title}</h4>
                                        <p className="text-gray-600">{row.description}</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const painPoints = [
        {
            icon: faExclamationTriangle,
            iconColor: 'text-red-500',
            title: 'Unsecured Data Management',
            description: 'Relying on spreadsheets and emails for data management increases the risk of data loss and confusion',
        },
        {
            icon: faExclamationTriangle,
            iconColor: 'text-red-500',
            title: 'Outdated Reports',
            description: 'Relying on periodic manual reports makes urgent decision-making difficult and increases overall project costs',
        },
        {
            icon: faExclamationTriangle,
            iconColor: 'text-red-500',
            title: 'Inefficient Resource Allocation',
            description: 'Traditional methods often lead to poor resource allocation, causing delays and increased costs.',
        },
    ];

    const solutions = [
        {
            icon: faCheckCircle,
            iconColor: 'text-green-500',
            title: 'Centralized Archive',
            description: 'Securely stores all data in one centralized repository, ensuring nothing is lost and document retrieval becomes easy and fast.',
        },
        {
            icon: faCheckCircle,
            iconColor: 'text-green-500',
            title: 'Real-Time Reporting',
            description: 'Provides real-time reports and insights, enabling quick, informed decisions and reducing project costs',
        },
        {
            icon: faCheckCircle,
            iconColor: 'text-green-500',
            title: 'AI-Optimized Resource Allocation',
            description: 'Analyzes project data to optimize resource allocation, ensuring timely and cost-effective execution',
        },
    ];

    return (
        <div ref={sectionRef} className="relative px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto font-light" style={{ textAlign: "left" }}>
            <div className="absolute inset-0 bg-gradient-to-r from-sky-800 from-5% to-blue-500 to-95% backdrop-blur-md"></div>
            <div className={`relative p-6 md:p-16 text-white max-w-screen-2xl m-auto lg:px-[10rem] ${isVisible ? 'animate-fadeIn' : 'opacity-0'}`}>
                <div className="mb-10 text-center">
                    <h2 className="text-3xl font-bold">Overcoming Traditional Mining Project Management Challenges</h2>
                </div>
                <div className="block md:hidden">
                    {renderTable('Traditional Pain Points', painPoints)}
                    {renderTable('Our Solutions', solutions)}
                </div>
                <div className="hidden md:block">
                    <div className={`overflow-x-auto shadow-lg rounded-lg transition duration-500 ease-in-out ${isVisible ? 'animate-slideIn' : 'opacity-0'}`}>
                        <table className="min-w-full bg-white border border-gray-300">
                            <thead>
                                <tr className="bg-gray-100 text-center">
                                    <th className="py-3 px-4 text-gray-800 border-b border-gray-300">Traditional Pain Points</th>
                                    <th className="py-3 px-4 text-gray-800 border-b border-gray-300">Our Solutions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {painPoints.map((point, index) => (
                                    <tr key={index} className="border-b border-gray-300 hover:bg-gray-50 transition duration-300 ease-in-out">
                                        <td className="p-4 md:p-8 border-r border-gray-300">
                                            <div className="flex items-start space-x-4">
                                                <FontAwesomeIcon icon={point.icon} className={`w-6 h-6 ${point.iconColor}`} />
                                                <div>
                                                    <h4 className="text-lg font-semibold text-gray-700">{point.title}</h4>
                                                    <p className="text-gray-600">{point.description}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="p-4 md:p-8">
                                            <div className="flex items-start space-x-4">
                                                <FontAwesomeIcon icon={solutions[index].icon} className={`w-6 h-6 ${solutions[index].iconColor}`} />
                                                <div>
                                                    <h4 className="text-lg font-semibold text-gray-700">{solutions[index].title}</h4>
                                                    <p className="text-gray-600">{solutions[index].description}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="text-center mt-10 px-4 md:px-16 lg:px-32 xl:px-48">
                    <p className="text-lg text-white mb-6">
                        Ready to revolutionize your mining projects?
                    </p>
                    <button
                        onClick={openModal}
                        className="inline-block bg-orange-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-orange-700 transition duration-300"
                    >
                        Contact Us Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PainPointsSection;
