import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LatestPosts.css';

const posts = [
    { title: 'Canada Business Expansion Week', excerpt: 'At RABIDDO, we celebrate the sharing of knowledge and experience. Our CEO joined an event as a speaker to share insights from their journey of expanding our business in Canada.', link: 'https://www.linkedin.com/feed/update/urn:li:activity:7203054875776106496', image: '/dglobalist.png' },
    { title: 'Canada Business Expansion Week | Video', excerpt: 'RABIDDO is proud to be a part of it, helping entrepreneurs who wish to expand their startups in Canada.', link: 'https://www.linkedin.com/feed/update/urn:li:activity:7203056487764525057', image: '/dglobalist-video.png' },
    { title: 'Collosion 2024', excerpt: 'We’re super excited to announce that we’ve been accepted into the Alpha program at Collision 2024! Come say hi and check us out at Stand A649 on Wednesday, June 19!', link: 'https://www.linkedin.com/feed/update/urn:li:activity:7205665949033590785', image: 'Collision.png' },
    { title: 'Supporting Immigrant Entrepreneurs', excerpt: 'We are honored to have participated as a panelist in the "Supporting Immigrant Entrepreneurs" discussion on June 10that Treefrog Accelerato Office.', link: 'https://www.linkedin.com/feed/update/urn:li:activity:7206405961320738816', image: 'Treefrog.jpg' },
    { title: '🌟 Proud Moment at Collision Conf 2024! 🌟', excerpt: 'We are delighted to share that RABIDDO had the honor of participating in Collision Conf 2024 in Toronto as an Alpha startup!', link: 'https://www.linkedin.com/feed/update/urn:li:activity:7209751739439210496', image: 'col-rabiddo.png' },
    { title: 'Canadian Mining Opportunities For Startups!', excerpt: 'We had a fantastic opportunity to meet with Shervin Teymouri, P.Eng, who brings years of experience in the Canadian mining market.', link: 'https://www.linkedin.com/feed/update/urn:li:activity:7218626129098600449', image: 'Mineit.png' },
    { title: 'Canadian Mining Opportunities For Startups!', excerpt: "Another productive meeting as we continue to explore opportunities for startups in the Canadian mining sector. This time, we had the pleasure of speaking with Dr. Manochehr Oliazadeh, who has dedicated nearly two decades to holding significant roles in some of Canada 's most reputable mining companies", link: 'https://www.linkedin.com/posts/rabiddo_another-productive-meeting-as-we-continue-activity-7222608029433647104-JBLx', image: 'worely.png' },
    { title: 'Exciting Times in Montreal!', excerpt: "We had an incredible opportunity to visit Sedgman Novopro’s office in Montreal and showcase our Project Management Software.", link: 'https://www.linkedin.com/feed/update/urn:li:activity:7227760840710512643', image: 'montreal.jpg' },
    { title: 'RABIDDO on Stage: Showcasing Innovation in Mining!', excerpt: 'We had the privilege of participating in the event, "Navigating New Frontiers: A Collaborative Journey in Innovation and Entrepreneurship Between Canada and Asia,".', link: 'https://www.linkedin.com/feed/update/urn:li:activity:7244496073598111744', image: 'pitch.jpg' }
]

const LatestPosts = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto font-light ">
            <div className="relative p-6 md:p-16 max-w-screen-2xl m-auto  bg-slate-200 ">
                <div className="text-center mb-10">
                    <h2 className="text-3xl font-bold">Latest News</h2>
                </div>
                <Slider {...settings}>
                    {posts.slice().reverse().map((post, index) => (
                        <div key={index} className="px-2">
                            <a href={post.link} target="_blank" rel="noopener noreferrer" className="block">
                                <div className="post-card bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                                    <img src={post.image} alt={post.title} className="post-image w-full object-cover rounded-lg mb-4" />
                                    <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                                    <p className="text-gray-600">{post.excerpt}</p>
                                </div>
                            </a>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default LatestPosts;
