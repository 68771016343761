import React, { useState } from 'react';
import Header from '../Header/Header';
import Hero from '../Header/Hero';
import Features from './Features/Features';
import PainPointsSection from './PainPoints/PainPointsSection';
import ContactUsModal from '../ContactUs/ContactUs';
import ProductsOverview from './Products/ProductsOverview';
import AboutUs from './AboutUs/AboutUs';
import LatestPosts from './LatestPosts/LatestsPosts';
import Footer from './Footer/Footer';

const Homepage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            <Header openModal={openModal} />
            <div id="hero-section">
                <Hero openModal={openModal} />
            </div>
            <div id="features-section">
                <Features />
            </div>
            <div id="painpoints-section">
                <PainPointsSection openModal={openModal} />
            </div>
            <div id="products-section">
                <ProductsOverview />
            </div>
            <div id="aboutus-section">
                <AboutUs openModal={openModal} />
            </div>
            <div id="">
                <LatestPosts />
            </div>
            <div id="">
                <Footer />
            </div>
            <ContactUsModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </>
    );
}

export default Homepage;

