import React, { useEffect, useRef, useState } from 'react';

const teamMembers = [
    { name: 'Mohammad Sepahi Chavoshloo', image: '/Mohammad.jpg', linkedin: 'https://www.linkedin.com/in/mohammad-sepahi-chavoshlou/' },
    { name: 'Majid Arabi', image: 'Majid.jpg', linkedin: 'https://www.linkedin.com/in/majid-arabi-270845133' },
    { name: 'Ata Badrikoohi', image: 'Ata.jpg', linkedin: 'https://www.linkedin.com/in/ata-badrikoohi-0815713a/' },
    { name: 'Alireza Sepahi Chavoshlou', image: 'Alireza.jpg', linkedin: 'https://www.linkedin.com/in/alireza-sepahi-chavoshlou-msc-pmp-244702125' },
    { name: 'Hossein Mehrpouya', image: 'Hossein.jpg', linkedin: 'https://www.linkedin.com/in/hossein-mehrpouya-7479b147/' }
];

const AboutUs = ({ openModal }) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (observer && observer.current) {
                observer.unobserve(observer.current);
            }
        };
    }, []);

    return (
        <div ref={sectionRef} className="relative px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto font-light" style={{ textAlign: "left" }}>
            <div className="absolute inset-0 bg-gradient-to-r from-sky-800 from-5% to-blue-500 to-95% backdrop-blur-md"></div>
            <div className={`relative p-6 md:p-16 text-white max-w-screen-2xl m-auto lg:px-[10rem] ${isVisible ? 'animate-fadeIn' : 'opacity-0'}`}>
                <div className="mb-10 text-center">
                    <h2 className="text-3xl font-bold">Our Team</h2>
                    <p className='mt-5'>Combining Expertise in Mining, Project Management, Engineering, and Computer Programming</p>
                </div>
                {/* <div className="flex flex-wrap lg:flex-nowrap justify-center gap-8">
                    {teamMembers.map(member => (
                        <div key={member.name} className={`team-member bg-white p-6 rounded-lg shadow-lg w-full sm:w-60 transition-transform duration-1000 ${isVisible ? 'transform translate-x-0' : 'transform -translate-x-10'}`}>
                            <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="block">
                                <img src={member.image} alt={member.name} className="team-member-image w-32 h-32 object-cover rounded-full mx-auto mb-4" />
                            </a>
                            <h3 className="text-xl font-semibold text-gray-700 text-center">{member.name}</h3>
                        </div>
                    ))}
                </div> */}
                <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
                    <div className=" text-center lg:flex-row lg:justify-between">
                        <div className="mb-6 lg:mb-0 lg:pr-6">
                            <h2 className="text-3xl font-bold text-gray-800 mb-4">We Have Skin in The Game!</h2>
                            <p className="text-gray-600">With over 25 years of experience managing EPC projects, we truly have skin in the game and know the bottlenecks well.</p>
                        </div>
                        <div>
                            <button
                                onClick={openModal}
                                className=" mt-5 inline-block bg-orange-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-orange-700 transition duration-300"
                            >
                                Contact Us
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
