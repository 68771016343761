import React from 'react';
import { motion } from 'framer-motion';

const Hero = ({ openModal }) => {
    return (
        <div className="hero bg-gray-100 flex items-end justify-start h-screen p-10 sm:p-10 md:p-20 lg:p-28 xl:px-[15rem] text-white">
            <motion.div
                className="text-left mb-24 pt-5"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <motion.h1
                    className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 1 }}
                >
                    Welcome to RABIDDO
                </motion.h1>
                <motion.div
                    className="text-lg sm:text-xl mt-2 mb-4"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1, duration: 1 }}
                >
                    AI-Powered Mining Project Assistant
                </motion.div>
                <motion.button
                    className="bg-orange-700 hover:bg-white hover:text-slate-800 text-white font-bold px-8 py-3 border border-orange-800 hover:border-transparent rounded mt-5"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.5, duration: 1 }}
                    onClick={openModal}
                >
                    Contact Us
                </motion.button>
            </motion.div>
        </div>
    );
};

export default Hero;
