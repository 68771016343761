import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassChart, faChartGantt, faPuzzlePiece, faFolderOpen, faBoxesStacked, faHelmetSafety, faUserTie, faHardDrive, faListCheck, faRocket } from '@fortawesome/free-solid-svg-icons';

const ProductsOverview = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = React.createRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (observer && observer.current) {
                observer.unobserve(observer.current);
            }
        };
    }, []);

    return (
        <div ref={sectionRef} className="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto font-light bg-white">
            <div className={`relative p-6 md:p-16 max-w-screen-2xl m-auto rounded-lg bg-slate-200 shadow-lg transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                <div className="text-center mb-10">
                    <h2 className="text-3xl font-bold">Products Overview</h2>
                    <p className='mt-5 font-bold'>
                        <FontAwesomeIcon icon={faPuzzlePiece} className="fa-lg text-red-500 ml-2" />
                        <span className='ml-2'>Essentials: Comprehensive Coverage for All Core Project Functions</span>
                    </p>
                </div>
                <div className="flex flex-wrap justify-center gap-8">
                    {[
                        {
                            icon: faFolderOpen,
                            color: 'text-blue-500',
                            title: 'Engineering Document Management',
                            description: 'Effortlessly manage, secure, and access your engineering documents, enhancing collaboration and improving project outcomes.',
                        },
                        {
                            icon: faBoxesStacked,
                            color: 'text-orange-500',
                            title: 'Procurement Management',
                            description: 'Streamline procurement processes from sourcing to settlement with enhanced collaboration and efficiency.',
                        },
                        {
                            icon: faHelmetSafety,
                            color: 'text-yellow-500',
                            title: 'Construction Management',
                            description: 'Enhance project coordination, streamline workflows, and gain real-time insights for superior construction management.',
                        },
                        {
                            icon: faChartGantt,
                            color: 'text-green-500',
                            title: 'Project Planning & Control',
                            description: 'Achieve precision and efficiency with advanced scheduling, effective budget management, and strategic resource allocation.',
                        },
                    ].map((product, index) => (
                        <div key={index} className={`product-card bg-white p-6 rounded-lg shadow-lg w-full sm:w-80 h-80 flex flex-col justify-between transition-transform duration-1000 delay-${index * 200} ${isVisible ? 'transform translate-x-0' : 'transform -translate-x-10'}`}>
                            <div>
                                <FontAwesomeIcon icon={product.icon} className={`w-10 h-10 ${product.color} mb-4`} />
                                <h3 className="text-xl font-semibold mb-2">{product.title}</h3>
                                <p className="text-gray-600">{product.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="text-center mb-10 mt-10">
                    <p className='mt-5 font-bold'>
                        <FontAwesomeIcon icon={faRocket} className="fa-lg text-violet-500 mr-2" />
                        Boosters: Take Your Project to the Next Level and Achieve New Heights
                    </p>
                </div>
                <div className="flex flex-wrap justify-center gap-8">
                    {[
                        {
                            icon: faUserTie,
                            color: 'text-green-500',
                            title: 'Technical Advisory Panel',
                            description: 'Connect with experienced technical experts to enhance your project processes in a cost-effective manner.',
                        },
                        {
                            icon: faMagnifyingGlassChart,
                            color: 'text-red-500',
                            title: 'Project Intelligence',
                            description: 'Gain real-time insights to guide work and resources in the right direction, optimizing both time and cost.',
                        },
                        {
                            icon: faHardDrive,
                            color: 'text-pink-500',
                            title: 'File Drive',
                            description: 'Store files privately or publicly, organize them into folders, share with others, and start conversations on each file.',
                        },
                        {
                            icon: faListCheck,
                            color: 'text-cyan-500',
                            title: 'Task Management',
                            description: 'Bring all your daily tasks into one place, start chats with colleagues on each task, and update them easily and smoothly.',
                        },
                    ].map((product, index) => (
                        <div key={index} className={`product-card bg-white p-6 rounded-lg shadow-lg w-full sm:w-80 h-80 flex flex-col justify-between transition-transform duration-1000 delay-${(index + 4) * 200} ${isVisible ? 'transform translate-x-0' : 'transform -translate-x-10'}`}>
                            <div>
                                <FontAwesomeIcon icon={product.icon} className={`w-10 h-10 ${product.color} mb-4`} />
                                <h3 className="text-xl font-semibold mb-2">{product.title}</h3>
                                <p className="text-gray-600">{product.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductsOverview;
