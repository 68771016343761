import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8  ">
                <div className="footer-grid pl-[8rem]">
                    <div className="footer-item text-center md:text-right">
                        <h3 className="footer-title">RABIDDO</h3>
                        <p className="footer-copy">© 2024 RABIDDO. All rights reserved.</p>
                        <p className="footer-copy">Follow us on LinkedIn for the latest updates.</p>
                    </div>
                    <div className="footer-item">
                        <h3 className="footer-title">Contact Us</h3>
                        <ul className="footer-list">
                            <li>
                                <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />
                                <a href="mailto:info@rabiddo.com" className="footer-link">info@rabiddo.com</a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faPhone} className="footer-icon" />
                                <a href="tel:+14376632298" className="footer-link">+1 437-663-2298</a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="footer-icon" />
                                <a href='https://www.google.com/maps/place/Toronto,+ON+M5X+1C7/@43.6488317,-79.3840011,17z/data=!3m1!4b1!4m6!3m5!1s0x882b34d290c767a9:0xfc550ea4eee470a6!8m2!3d43.6486599!4d-79.3815764!16s%2Fm%2F09r_cv_?entry=ttu' target='_blank' className="footer-link">100 King Street West, Toronto, Ontario, M5X 1C7</a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faLinkedin} className="footer-icon" />
                                <a href="https://www.linkedin.com/company/rabiddo" target="_blank" rel="noopener noreferrer" className="footer-link">LinkedIn</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </footer>
    );
};

export default Footer;
