import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // This is important for accessibility reasons, ensuring that screen readers can see the modal

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px',
        padding: '2rem',
        borderRadius: '10px',
        backgroundColor: 'white',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
};

const ContactUsModal = ({ isOpen, onRequestClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            contentLabel="Contact Us Modal"
        >
            <div className="text-center">
                <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
                <p className="mb-6 text-lg text-gray-700">
                    We would love to hear from you. Please reach out to us via phone or email.
                </p>
                <div className="grid grid-cols-1 gap-8">
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Phone</h3>
                        <p className="text-lg">+14376632298</p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Email</h3>
                        <p className="text-lg">info@rabiddo.com</p>
                    </div>
                </div>
                <button
                    onClick={onRequestClose}
                    className="mt-6 inline-block bg-orange-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-orange-700 transition duration-300"
                >
                    Close
                </button>
            </div>
        </Modal>
    );
};

export default ContactUsModal;
