import React, { useState } from 'react';
import logo from '../logo.png';
import { Link } from 'react-scroll';

const Header = ({ openModal }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className="w-full bg-white text-slate-950 p-5 flex justify-between items-center px-6 lg:px-20 xl:px-[15rem]">
            <div className="flex items-center font-black text-xl">
                <img src={logo} height="30" width="30" className="mr-2" alt="Logo" />
                <span>RABIDDO</span>
            </div>
            <div className="hidden md:flex space-x-4">
                <Link to="hero-section" smooth={true} duration={500} className="text-slate-800 font-bold px-4 py-2 hover:underline cursor-pointer">Home</Link>
                <Link to="products-section" smooth={true} duration={500} className="text-slate-800 font-bold px-4 py-2 hover:underline cursor-pointer">Products</Link>
                <Link to="aboutus-section" smooth={true} duration={500} className="text-slate-800 font-bold px-4 py-2 hover:underline cursor-pointer">About Us</Link>
                <button onClick={openModal} className="bg-transparent hover:bg-blue-600 text-slate-700 font-bold hover:text-white py-2 px-4 border border-slate-800 hover:border-transparent rounded">
                    Contact Us
                </button>
            </div>
            <div className="md:hidden">
                <button onClick={handleMobileMenuToggle} className="text-slate-800 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                    </svg>
                </button>
            </div>
            {isMobileMenuOpen && (
                <div className="absolute top-16 left-0 w-full bg-white shadow-lg flex flex-col items-center space-y-4 py-4 md:hidden">
                    <Link to="hero-section" smooth={true} duration={500} className="text-slate-800 font-bold px-4 py-2 hover:underline cursor-pointer">Home</Link>
                    <Link to="products-section" smooth={true} duration={500} className="text-slate-800 font-bold px-4 py-2 hover:underline cursor-pointer">Products</Link>
                    <Link to="aboutus-section" smooth={true} duration={500} className="text-slate-800 font-bold px-4 py-2 hover:underline cursor-pointer">About Us</Link>
                    <button
                        onClick={openModal}
                        className="bg-transparent hover:bg-blue-600 text-slate-700 font-bold hover:text-white py-2 px-4 border border-slate-800 hover:border-transparent rounded">
                        Contact Us
                    </button>
                </div>
            )}
        </div>
    );
};

export default Header;
